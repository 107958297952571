/* Global vars */

.desktopRoot {
  --app-bar-height: 48px;
}

.mobileRoot {
  --app-bar-height: 45px;
  --dock-height: 52px;
  --resident-header-height: 112px;
}

/* Global styles */

:root,
body,
div#root {
  height: 100%;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 #f2f2f2;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f2f2f2;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border: 3px none #ffffff;
  border-radius: 4px;
}

.mobile-container,
nav {
  /* MS, Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mobile-container::-webkit-scrollbar,
nav::-webkit-scrollbar {
  /* Safari, Chrome */
  display: none;
}

/*.mobile #dataTableContainerTemplate {*/
/*  width: 100%;*/
/*}*/

/*.mobile #dataTableContainerTemplate.hasFilter {*/
/*  margin-top: 55px;*/
/*}*/

/*.mobile #dataTableContainerTemplate.hasDropdownFilter {*/
/*  margin-top: 62px;*/
/*}*/

/*.mobile .dataTableToolbar ~ #dataTableContainerTemplate.hasFilter {*/
/*  margin-top: 50px;*/
/*}*/

/*.mobile .dataTableToolbar ~ #dataTableContainerTemplate.hasDropdownFilter {*/
/*  margin-top: 65px;*/
/*}*/

/*.mobile #dataTableContainerTemplate.hasFilter.forceDesktop,*/
/*.mobile #dataTableContainerTemplate.hasDropdownFilter.forceDesktop {*/
/*  margin-top: 0;*/
/*}*/

.mobile .templateBody {
  display: flex;
  flex-direction: column;
}

.mobile .cardDataTable {
  background-color: #f5f5f5;
  box-shadow: none;
}

.mobile .dropdownToolBar.dataTableToolbar,
.mobile .toolBar.dataTableToolbar {
  box-shadow: none;
  padding-bottom: 0;
}

.mobile {
  background-color: #f5f5f5;
}

.cardBody {
  color: rgba(0, 0, 0, 0.64);
}

.mobile .clamped {
  margin-bottom: 5px;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.headerTitle {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.64);
}

.headerInfo {
  font-weight: bold;
}

.scrollShadowTop {
  position: relative;
}

.scrollShadowTop::before {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
  left: 0;
  width: 100%;
  top: 0;
  height: 6px;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 3;
}

.scrollShadowTop.scrolled::before {
  opacity: 1;
  transform: scaleY(1);
}

.scrollShadowBottom {
  position: relative;
}

.scrollShadowBottom::before {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
  width: 100%;
  left: 0;
  bottom: -6px;
  height: 6px;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 3;
}

.scrollShadowBottom.scrolled::before {
  opacity: 1;
  transform: scaleY(1);
}

.filterItem {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  display: flex;
}

.filterItem:first-child {
  margin-top: 0;
}

.desktopRoot header .appBarIconButton {
  padding: 8px;
}

.desktopRoot header .appBarIconButton svg {
  width: 32px;
  height: 32px;
}

.desktopRoot header .appBarIconButton:last-child {
  margin-right: -8px;
}
